import { BrManageContentButton, BrPageContext, BrProps } from '@bloomreach/react-sdk';
import { ImageSet } from '@bloomreach/spa-sdk';
import React, { useContext, useEffect, useState } from 'react';
import { useViewport } from './hooks/useViewport';
import { Link } from './Link';

export function ImageTextHorizontalCardComponent(props: BrProps) {
  const { document: documentRef } = props.component.getModels();
  const document = documentRef && props.page.getContent(documentRef);
  // If no document selected, don't render anything
  const { width } = useViewport();
  const breakpoint = 991;
  if (!document) {
    return null;
  }

  // If the wrong document type is selected, show a message in the CMS but not on the site
  const { contentType } = document.getData();
  if (props.page.isPreview() && contentType !== 'brxmbasic:imageTextHorizontalCardComponent') {
    return <h3>Wrong document type selected. Please select a Hero Component Document</h3>;
  }

  // Get the hero component document data
  const {
    buttonCompound,
    header,
    images,
    layout = 'left', // Set the default layout to image on the left side
    linkListCompound,
    linkListHeading,
    paragraph,
    textColor = 'red', // Default text color is red
  } = document.getData();

  // Render the Component
  return (
    <div>
      {width > breakpoint ? (
        <div
          className={`${layout === 'left' ? 'image-left-card' : 'img-right-card'} w-row`}
          style={{ position: 'relative' }}
          data-testid="ImageTextHorizontalCardComponent"
        >
          <BrManageContentButton content={document} />
          {layout === 'left' ? (
            <>
              <ImageSubComponent images={images} layout={layout} />
              <TextSubComponent
                layout={layout}
                buttonCompound={buttonCompound}
                header={header}
                paragraph={paragraph}
                linkListCompound={linkListCompound}
                linkListHeading={linkListHeading}
                textColor={textColor}
              />
            </>
          ) : (
            <>
              <TextSubComponent
                layout={layout}
                buttonCompound={buttonCompound}
                header={header}
                paragraph={paragraph}
                linkListCompound={linkListCompound}
                linkListHeading={linkListHeading}
                textColor={textColor}
              />
              <ImageSubComponent images={images} layout={layout} />
            </>
          )}
        </div>
      ) : (
        <div
          className="image-left-card w-row"
          style={{ position: 'relative' }}
          data-testid="ImageTextHorizontalCardComponentMobile"
        >
          <BrManageContentButton content={document} />
          <>
            <ImageSubComponent images={images} layout={layout} />
            <TextSubComponent
              layout={layout}
              buttonCompound={buttonCompound}
              header={header}
              paragraph={paragraph}
              linkListCompound={linkListCompound}
              linkListHeading={linkListHeading}
              textColor={textColor}
            />
          </>
        </div>
      )}
    </div>
  );
}

export const ImageSubComponent = ({ images, layout }: any) => {
  const page = useContext(BrPageContext);

  // Get the mobile image
  const mobileImageRef = images?.mobileImage;
  const mobileImage = mobileImageRef && page?.getContent<ImageSet>(mobileImageRef);

  // Get the desktop image
  const desktopImageRef = images?.desktopImage;
  const desktopImage = desktopImageRef && page?.getContent<ImageSet>(desktopImageRef);
  //console.log('-----------sss', desktopImage);
  return (
    <div className="left-card-img-col w-col w-col-7 w-col-stack" data-testid="ImageSubComponent">
      {desktopImage && (
        <img src={desktopImage?.getOriginal()?.getUrl()} loading="lazy" alt="" className="pillar-purpose-desktop" />
      )}
      {mobileImage && (
        <img src={mobileImage?.getOriginal()?.getUrl()} loading="lazy" alt="" className="pillar-purpose-mobile" />
      )}
    </div>
  );
};

export const TextSubComponent = ({
  layout,
  buttonCompound,
  header,
  paragraph,
  linkListCompound,
  linkListHeading,
  textColor,
}: any) => {
  const page = useContext(BrPageContext);
  const buttonIcon = buttonCompound?.buttonIcon && page?.getContent<ImageSet>(buttonCompound?.buttonIcon);

  //console.log('page', page);
  return (
    <div
      className={`${layout === 'left' ? 'left-card-txt-col' : 'right-card-text-col'} w-col w-col-5 w-col-stack`}
      data-testid="TextSubComponent"
    >
      <div className={`initiatives-card ${layout === 'left' ? 'right' : 'left'}`}>
        {header && page && (
          <h1
            className={`pillar-header ${textColor}`}
            dangerouslySetInnerHTML={{ __html: page?.rewriteLinks(header) }}
          ></h1>
        )}
        {paragraph && <p className="paragraph">{paragraph}</p>}
        {linkListHeading && <h1 className="heading">{linkListHeading}</h1>}
        {linkListCompound && (
          <ul className="list">
            {linkListCompound.map((item: any, key: number) => {
              const link = page?.getContent(item?.link)?.getUrl();
              return (
                <li className="initiative-list-item" key={key}>
                  {item?.linkText && link && (
                    <Link href={`${link}${item?.linkAnchor}`} className="initiative-link">
                      {item?.linkText}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        )}
        {buttonCompound && (
          <div className="learn-more-btn-block">
            {buttonCompound?.link && buttonCompound?.buttonText && (
              <Link
                href={page?.getContent(buttonCompound?.link)?.getUrl()}
                className={`button padding w-button ${textColor}`}
              >
                {buttonCompound?.buttonText}
              </Link>
            )}
            {buttonIcon && (
              <img src={buttonIcon?.getOriginal()?.getUrl()} loading="lazy" alt="" className="learn-more-arrow" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageTextHorizontalCardComponent;
