import React from 'react';

const FooterDownloadMenu = () => {
  //   const component = React.useContext(BrComponentContext);
  //   const page = React.useContext(BrPageContext);
  //   const menuRef = component?.getModels<MenuModels>()?.menu;
  //   const menu = menuRef && page?.getContent<BrMenu>(menuRef);

  //   if (!isMenu(menu)) {
  //     return null;
  //   }

  return (
    <>
      <ul role="list" className={`footer-list-items second w-list-unstyled`}>
        <li className="footer-title" data-testid="download-title">
          Download Our App
        </li>
      </ul>
      {/* <div
        className={`download-app-links ${
          page!.isPreview() ? 'has-edit-button' : ''
        }`}
      > */}
      {/* <BrManageMenuButton menu={menu} /> */}
      {/* {menu.getItems().map((item: MenuItem, index: number) => (
          <a
            href={item.getUrl()}
            className="social-link-block w-inline-block"
            key={index}
          >
            <Icon name={item.getName()} />
          </a>
        ))} */}
      <a
        href="https://apps.apple.com/us/app/raleys/id582172238"
        className="w-inline-block"
      >
        <img
          src="https://assets.website-files.com/6063cd65aa6a07d366c44ab1/60689a734e5efd6d000e630c_apple.svg"
          loading="lazy"
          alt="apple"
          className="app-link apple"
          data-testid="apple"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.raleys.app.android"
        className="w-inline-block"
      >
        <img
          src="https://assets.website-files.com/6063cd65aa6a07d366c44ab1/60689a73934dc4c151f32ce9_google-play.svg"
          loading="lazy"
          alt="google"
          className="app-link google-play"
          data-testid="google"
        />
      </a>
      {/* </div> */}
    </>
  );
};

export default FooterDownloadMenu;
