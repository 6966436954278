import React from 'react';

const FooterEmail = () => {
  return (
    <>
      <div className="form-block w-form">
        <form
          id="wf-form-Email-Form"
          name="wf-form-Email-Form"
          data-name="Email Form"
          action="https://www.raleys.com/store-locator/"
          className="form"
        >
          <input
            type="text"
            className="zip-code-input w-input"
            maxLength={256}
            name="name"
            data-name="Name"
            data-testid="zipcode"
            placeholder="Enter Zip Code"
            id="name"
          />
          <img
            src="https://assets.website-files.com/6063cd65aa6a07d366c44ab1/6068b32973bd5ed166beeecf_search.svg"
            loading="lazy"
            alt="search"
            className="search-svg"
            data-testid="search"
          />
        </form>
        <div className="w-form-done" data-testid="done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail" data-testid="fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </>
  );
};

export default FooterEmail;
