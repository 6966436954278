import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document, Reference } from '@bloomreach/spa-sdk';
import React from 'react';

interface TextCardComponentModels {
  document: Reference;
}

export function TextCardComponent({ component, page }: BrProps) {
  const { document: documentRef } = component.getModels<TextCardComponentModels>();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    //if in preview mode return placeholder if no card is set on component, else return null;
    return page.isPreview() ? (
      <div className="has-edit-button" style={{ minWidth: '150px' }}>
        <BrManageContentButton content={document} />
      </div>
    ) : null;
  }

  const { cardCss, cardTitle, cardTitleCss, cardText } = document.getData<DocumentData>();

  return (
    <div className={`columns-5 w-row ${cardCss}`}>
      <BrManageContentButton content={document} />
      <div className="w-col w-col-6 w-col-stack" data-testid="cardTitle">
        {cardTitle && <div className={`${cardTitleCss}`}>{cardTitle}</div>}
      </div>
      <div className="w-col w-col-6 w-col-stack" data-testid="cardText">
        {cardText && <div dangerouslySetInnerHTML={{ __html: page.rewriteLinks(cardText.value) }} />}
      </div>
    </div>
  );
}
