/*
 * Copyright 2019-2020 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { ImageSet } from '@bloomreach/spa-sdk';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';

export function HeroComponent(props: BrProps) {
  const { document: documentRef } = props.component.getModels();
  const document = documentRef && props.page.getContent(documentRef);

  if (!document) {
    //if in preview mode return placeholder if no card is set on component, else return null;
    return props.page.isPreview() ? (
      <div className="has-edit-button" style={{ minWidth: '150px', position: 'relative' }}>
        <BrManageContentButton
          documentTemplateQuery="new-heroComponent-document"
          folderTemplateQuery="new-heroComponent-folder"
          parameter="document"
          root="hero-components"
          relative
        />
      </div>
    ) : null;
  }
  //console.log(document);

  // If the wrong document type is selected, show a message in the CMS but not on the site
  const { contentType } = document.getData();
  if (props.page.isPreview() && contentType !== 'brxmbasic:heroComponent') {
    return <h1>Wrong document type selected. Please select a Hero Component Document</h1>;
  }

  // Get the hero component document data
  const { content, heroTitle, heroImage, logo: logoRef } = document.getData();

  // Get the logo image
  const logo = logoRef && props.page.getContent<ImageSet>(logoRef);

  // Get the mobile image set
  const mobileImageRef = heroImage?.mobileImage;
  const mobileImageSet = mobileImageRef && props.page.getContent<any>(mobileImageRef);
  const mobileFiveHundredPixels = mobileImageSet?.model?.data?.fiveHundredPixels?.links?.site?.href;
  const mobileSevenHundredFiftyPixels = mobileImageSet?.model?.data?.sevenHundredFiftyPixels?.links?.site?.href;

  // Get the desktop image set
  const desktopImageRef = heroImage?.desktopImage;
  const desktopImageSet = desktopImageRef && props.page.getContent<any>(desktopImageRef);
  const desktopFiveHundredPixels = desktopImageSet?.model?.data?.fiveHundredPixels?.links?.site?.href;
  const desktopEightHundredPixels = desktopImageSet?.model?.data?.eightHundredPixels?.links?.site?.href;
  const desktopOneThousandEightyPixels = desktopImageSet?.model?.data?.oneThousandEightyPixels?.links?.site?.href;
  const desktopSixteenHundredPixels = desktopImageSet?.model?.data?.sixteenHundredPixels?.links?.site?.href;

  //console.log(logo?.getOriginal());
  // Render the Hero Component
  return (
    <div className="hero-img-section">
      <div
        className={`hero-block new ${props.page.isPreview() ? 'has-edit-button' : ''}`}
        style={{ position: 'relative' }}
      >
        <BrManageContentButton content={document} />
        {logo && (
          <img
            className="raleys-logo"
            src={logo?.getOriginal()?.getUrl()}
            alt="Raleys Logo"
            data-testid="raleys-logo"
          />
        )}

        {heroTitle && (
          <div
            className="hero-title-block"
            dangerouslySetInnerHTML={{
              __html: props.page.rewriteLinks(heroTitle.value),
            }}
          />
        )}

        {desktopImageSet && (
          <img
            src={desktopImageSet?.getOriginal()?.getUrl()}
            loading="lazy"
            sizes="(max-width: 991px) 100vw, (max-width: 1279px) 92vw, (max-width: 1919px) 95vw, 99vw"
            srcSet={`
            ${desktopFiveHundredPixels ? desktopFiveHundredPixels + ' 500w' : ''},
            ${desktopEightHundredPixels ? desktopEightHundredPixels + ' 800w' : ''},
            ${desktopOneThousandEightyPixels ? desktopOneThousandEightyPixels + ' 1080w' : ''},
            ${desktopSixteenHundredPixels ? desktopSixteenHundredPixels + ' 1600w' : ''},
          `}
            alt="desktopImage"
            data-testid="desktopImage"
            className={`${heroImage?.desktopImageClass ? heroImage?.desktopImageClass : ''}`}
          />
        )}
        {mobileImageSet && (
          <img
            src={mobileImageSet?.getOriginal()?.getUrl()}
            loading="lazy"
            sizes="100vw"
            srcSet={`
            ${mobileFiveHundredPixels ? mobileFiveHundredPixels + ' 500w' : ''},
            ${mobileSevenHundredFiftyPixels ? mobileSevenHundredFiftyPixels + ' 750w' : ''}
          `}
            alt="moblieImage"
            data-testid="moblieImage"
            className={`${heroImage?.mobileImageClass ? heroImage?.mobileImageClass : ''}`}
          />
        )}

        {content && (
          <div
            className="hero-text-block"
            dangerouslySetInnerHTML={{
              __html: props.page.rewriteLinks(content.value),
            }}
            data-testid="hero-content"
          />
        )}
      </div>
    </div>
  );
}
