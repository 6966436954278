/*
 * Copyright 2020 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
//import { Link as RouterLink } from 'react-router-dom';
import { HashLink as RouterLink } from 'react-router-hash-link';
// ref : https://dev.to/gedalyakrycer/5-remarkable-react-router-features-anchor-links-query-params-more-2aeg

export const Link = React.forwardRef(
  ({ href, ...props }: React.ComponentPropsWithoutRef<'a'>, ref: React.Ref<HTMLAnchorElement>) =>
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    href ? <RouterLink smooth ref={ref} to={href} {...props} /> : <a ref={ref} role="button" {...props} />,
);
