import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export function SearchEngineOptimization({ component, page }: BrProps) {
  const params = component.getParameters();
  const template = (strings, ...keys) => {
    return function (...values) {
      let dict = values[values.length - 1] || {};
      let result = [strings[0]];
      keys.forEach(function (key, i) {
        let value = Number.isInteger(key) ? values[key] : dict[key];
        result.push(value, strings[i + 1]);
      });
      return result.join('');
    };
  };

  var pageTitle = template`${'siteTitle'}`(params);
  return (
    <div className={`${page.isPreview() ? 'has-edit-button' : ''}`}>
      <BrManageContentButton />
      <Helmet>
        <title>{pageTitle}</title>
        {params.documentKeywordsBeanProperties && (
          <meta name="keywords" content={`${params.documentKeywordsBeanProperties}`} />
        )}
        {params.documentTitleBeanProperties && (
          <meta content={`${params.documentTitleBeanProperties}`} property="og:title" />
        )}
        {params.documentTitleBeanProperties && (
          <meta content={`${params.documentTitleBeanProperties}`} property="twitter:title" />
        )}
        {params.documentDescriptionBeanProperties && (
          <meta content={`${params.documentDescriptionBeanProperties}`} property="og:description" />
        )}
        {params.documentDescriptionBeanProperties && (
          <meta content={`${params.documentDescriptionBeanProperties}`} property="twitter:description" />
        )}
        {params.documentImageBeanProperties && (
          <meta content={`${params.documentImageBeanProperties}`} property="og:image" />
        )}
        {params.documentImageBeanProperties && (
          <meta content={`${params.documentImageBeanProperties}`} property="twitter:image" />
        )}
      </Helmet>
    </div>
  );
}
