import { BrComponentContext, BrManageMenuButton, BrPageContext } from '@bloomreach/react-sdk';
import { isMenu, Menu as BrMenu, Reference } from '@bloomreach/spa-sdk';
import React from 'react';
import { SidebarMenu } from './SidebarMenu';

interface MenuModels {
  menu: Reference;
}

export function Sidebar({ setColor, onClick }) {
  const component = React.useContext(BrComponentContext);
  const page = React.useContext(BrPageContext);
  const menuRef = component?.getModels<MenuModels>()?.menu;
  const menu = menuRef && page?.getContent<BrMenu>(menuRef);

  if (!isMenu(menu)) {
    return null;
  }
  return (
    <div className={`nav-sidebar-body ${page!.isPreview() ? 'has-edit-button' : ''}`} data-testid="sidebar">
      <BrManageMenuButton menu={menu} />
      {menu.getItems().map((item, idx) => {
        return <SidebarMenu item={item} key={idx} setColor={setColor} onClick={onClick} />;
      })}
    </div>
  );
}
