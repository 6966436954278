import React from 'react';
import { BrComponent } from '@bloomreach/react-sdk';

import FooterMainMenu from './FooterMainMenu';
import FooterSocialMenu from './FooterSocialMenu';

import './Footer.css';

export function Footer() {
  return (
    <div className="footer">
      <div className="red-strip">
        <img
          src="https://assets.website-files.com/6063cd65aa6a07d366c44ab1/6064c96ff911384c72018151_Group%20149.png"
          loading="lazy"
          alt=""
          className="image-5"
        />
      </div>

      <div className="footer-main">
        <BrComponent path="footerMain">
          <FooterMainMenu />
        </BrComponent>
        <div className="footer-bottom">
          <img
            src="https://assets.website-files.com/6063cd65aa6a07d366c44ab1/60689a749c3b8554937affa8_Tri%20Horizontal%20Red%202.png"
            loading="lazy"
            alt=""
            className="image-17"
          />
          <BrComponent path="footerSocial">
            <FooterSocialMenu />
          </BrComponent>

          <div className="fine-print">
            <a href="https://www.raleys.com/terms-of-use/" className="footer-link-css">
              <div className="fine-print-text"> Terms and conditions </div>
            </a>
            <a href="https://www.raleys.com/privacy-policy/" className="footer-link-css">
              <div className="fine-print-text"> Privacy policy</div>
            </a>
            <a
              href="https://www.raleys.com/ccpa-california-consumer-privacy-act-your-rights/"
              className="footer-link-css"
            >
              <div className="fine-print-text"> CCPA FAQ </div>
            </a>
            <div className="fine-print-text">
              <strong>&copy;</strong> {new Date().getFullYear()} Raley's All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
