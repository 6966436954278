/*
 * Copyright 2019-2020 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { ImageSet } from '@bloomreach/spa-sdk';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';

export function NewsFeedComponent(props: BrProps) {
  const { document: documentRef } = props.component.getModels();
  const document = documentRef && props.page.getContent(documentRef);
  // If no document selected, don't render anything

  if (!document) {
    return null;
  }

  //Get the hero component document data
  const { newFeedsCard, title } = document.getData();
  //console.log(newFeedsCard);
  // get all the imageref for the newFeedsCard array of object
  if (newFeedsCard) {
    for (let i = 0; i < newFeedsCard.length; i++) {
      newFeedsCard[i].desktopImage = props.page.getContent<ImageSet>(
        newFeedsCard[i].imageCompound.desktopImage,
      );
      newFeedsCard[i].mobileImage = props.page.getContent<ImageSet>(
        newFeedsCard[i].imageCompound.mobileImage,
      );
    }
  }
  // Render the NewsFeed Component
  return (
    <div data-testid="container">
      <div
        className={`news-feed-container ${
          props.page.isPreview() ? 'has-edit-button' : ''
        }`}
        style={{ position: 'relative' }}
      >
        <BrManageContentButton content={document} />

        <div className="news-feed-title" data-testid="title">
          {title}
        </div>

        <div className="news-articles">
          {newFeedsCard &&
            newFeedsCard.map((ele, idx) => (
              <a
                href={ele.externalLink}
                key={idx}
                className="news-article-container w-inline-block"
                data-testid="redirectLink"
              >
                <div className="news-article-img-block">
                  <img
                    className="crop-img"
                    src={ele.desktopImage?.getOriginal()?.getUrl()}
                    loading="lazy"
                    alt="desktopImage"
                    data-testid="desktopImage"
                  />
                  <img
                    className="crop-img mobile"
                    src={ele.mobileImage?.getOriginal()?.getUrl()}
                    loading="lazy"
                    alt="moblieImage"
                    data-testid="moblieImage"
                  />
                </div>
                <div className="news-article-info-block" data-testid="test">
                  <div className="news-article-date" data-testid="feedDate">
                    {ele.date}
                  </div>
                  <div className="text-block-17" data-testid="feedTitle">
                    {ele.title}
                  </div>
                  <div className="text-block-18" data-testid="feedDescription">
                    {ele.description}
                  </div>
                </div>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}

export default NewsFeedComponent;
