import * as React from "react";
function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <div className="error-Container">
        <img
          className="background-image"
          src="https://assets.website-files.com/6063cd65aa6a07d366c44ab1/606a8fbb05ece02481e211f1_HomepageHero%20(1).png"
          alt=""
        />
        <p className="error-title">Page is Currently In Maintenance...</p>
        <pre className="error-body">{error.message}</pre>
      </div>
    </div>
  );
}

export default ErrorFallback;
