import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import React from 'react';

export function CardComponent({ component, page }: BrProps) {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent(documentRef);

  if (!document) {
    //if in preview mode return placeholder if no card is set on component, else return null;
    return page.isPreview() ? (
      <div className="has-edit-button" style={{ minWidth: '150px', position: 'relative' }}>
        <BrManageContentButton content={document} />
      </div>
    ) : null;
  }

  const { cardSize, cardCss, cardImage, cardText, cardImageLocation } = document.getData();
  const mobileImageRef = cardImage.mobileImage;
  const mobileImageSet = mobileImageRef && page.getContent<any>(mobileImageRef);
  const mobileFiveHundredPixels = mobileImageSet?.model?.data?.fiveHundredPixels?.links?.site?.href || '';
  const mobileSevenHundredFiftyPixels = mobileImageSet?.model?.data?.sevenHundredFiftyPixels?.links?.site?.href || '';

  const desktopImageRef = cardImage.desktopImage;
  const desktopImageSet = desktopImageRef && page.getContent<any>(desktopImageRef);
  const desktopFiveHundredPixels = desktopImageSet?.model?.data?.fiveHundredPixels?.links?.site?.href || '';
  const desktopEightHundredPixels = desktopImageSet?.model?.data?.eightHundredPixels?.links?.site?.href || '';
  const desktopOneThousandEightyPixels = desktopImageSet?.model?.data?.oneThousandEightyPixels?.links?.site?.href || '';
  const desktopSixteenHundredPixels = desktopImageSet?.model?.data?.sixteenHundredPixels?.links?.site?.href || '';

  const leftCol = () => {
    switch (cardSize) {
      case 'small':
        return 'column-4 w-col w-col-8';
      case 'medium':
        return 'column-4 w-col w-col-8 w-col-stack';
      case 'large':
        return 'column-8 w-col w-col-6 w-col-stack';
    }
  };
  const rightCol = () => {
    switch (cardSize) {
      case 'small':
        return 'column-5 w-col w-col-4';
      case 'medium':
        return 'column-5 w-col w-col-4 w-col-stack';
      case 'large':
        return 'column-9 w-col w-col-6 w-col-stack';
    }
  };

  return (
    <div className={`${cardCss} ${page.isPreview() ? 'has-edit-button' : ''}`}>
      <BrManageContentButton content={document} />
      <div className="columns-5 w-row">
        <div className={`${leftCol()} `} data-testid="cardText">
          {cardText && (
            <div
              dangerouslySetInnerHTML={{
                __html: page.rewriteLinks(cardText.value),
              }}
            />
          )}
        </div>
        <div
          className={`${rightCol()} `}
          style={cardImageLocation.indexOf('left') >= 0 ? { order: -1 } : {}}
          data-testid="cardImage"
        >
          {mobileImageSet && (
            <img
              className={cardImage.mobileImageClass}
              src={mobileImageSet.getOriginal()?.getUrl()}
              alt="test"
              loading="lazy"
              sizes="100vw"
              srcSet={`
            ${mobileFiveHundredPixels ? mobileFiveHundredPixels + ' 500w' : ''},
            ${mobileSevenHundredFiftyPixels ? mobileSevenHundredFiftyPixels + ' 750w' : ''}
          `}
            />
          )}
          {desktopImageSet && (
            <img
              className={cardImage.desktopImageClass}
              src={desktopImageSet.getOriginal()?.getUrl()}
              alt=""
              loading="lazy"
              sizes="(max-width: 991px) 100vw, (max-width: 1279px) 92vw, (max-width: 1919px) 95vw, 99vw"
              srcSet={`
            ${desktopFiveHundredPixels ? desktopFiveHundredPixels + ' 500w' : ''},
            ${desktopEightHundredPixels ? desktopEightHundredPixels + ' 800w' : ''},
            ${desktopOneThousandEightyPixels ? desktopOneThousandEightyPixels + ' 1080w' : ''},
            ${desktopSixteenHundredPixels ? desktopSixteenHundredPixels + ' 1600w' : ''},
          `}
            />
          )}
        </div>
      </div>
    </div>
  );
}
