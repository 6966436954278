import React from 'react';
import { MenuItem, Menu as BrMenu, isMenu } from '@bloomreach/spa-sdk';
import { BrComponentContext, BrManageMenuButton, BrPageContext } from '@bloomreach/react-sdk';

import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg';
import { ReactComponent as Youtube } from '../../assets/icons/youtube.svg';

const Icon = ({ name }: any) => {
  switch (name) {
    case 'Facebook':
      return <Facebook />;
    case 'Twitter':
      return <Twitter />;
    case 'Instagram':
      return <Instagram />;
    case 'Youtube':
      return <Youtube />;
    default:
      break;
  }

  return null;
};

const FooterSocialMenu = () => {
  const component = React.useContext(BrComponentContext);
  const page = React.useContext(BrPageContext);
  const menuRef = component?.getModels<MenuModels>()?.menu;
  const menu = menuRef && page?.getContent<BrMenu>(menuRef);

  if (!isMenu(menu)) {
    return null;
  }
  //console.log('social', menu.getItems()[0].getUrl());
  return (
    <div className={`socials ${page!.isPreview() ? 'has-edit-button' : ''}`} data-testid="FooterSocialMenu">
      <BrManageMenuButton menu={menu} />
      {menu.getItems().map((item: MenuItem, index: number) => {
        return (
          <a href={item.getUrl()} className="social-link-block w-inline-block" key={index}>
            <Icon name={item.getName()} />
          </a>
        );
      })}
    </div>
  );
};

export default FooterSocialMenu;
