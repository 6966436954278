import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';

export function HeaderComponent(props: BrProps) {
  const { document: documentRef } = props.component.getModels();
  const document = documentRef && props.page.getContent(documentRef);

  if (!document) {
    return null;
  }
  //console.log(document);

  const { title, assetLink: linkRef } = document.getData();
  const link = linkRef && props.page.getContent<any>(linkRef);
  const pdfLink = link?.data?.asset?.links?.site?.href;

  return (
    <h1
      className={`impact-report ${
        props.page.isPreview() ? 'has-edit-button' : ''
      }`}
      data-testid="content"
    >
      {pdfLink && (
        <a
          href={pdfLink}
          className="header-impact-report-link"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="title"
        >
          {title}
        </a>
      )}
    </h1>
  );
}
