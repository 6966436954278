import React from 'react';
import { ImageSet } from '@bloomreach/spa-sdk';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';

export function CareerComponent({ component, page }: BrProps) {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent(documentRef);

  // If no document selected, don't render anything
  if (!document) {
    return null;
  }

  //console.log(JSON.stringify(document));
  const { imageCompound, title, header, description, linkUrl, linkText, linkIcon } = document.getData();

  // if (!flyerRef) {
  //   console.log(`NourishingContentComponent: flyerRef is undefined`);
  // }

  // Get the mobile image
  const mobileImageRef = imageCompound?.mobileImage;
  const mobileImage = mobileImageRef && page.getContent<ImageSet>(mobileImageRef);

  console.log('mobileImage', mobileImage);
  // Get the desktop image
  const desktopImageRef = imageCompound?.desktopImage;
  const desktopImage = desktopImageRef && page.getContent<ImageSet>(desktopImageRef);

  return (
    <div className={`hero-block new ${page.isPreview() ? 'has-edit-button' : ''}`}>
      <BrManageContentButton content={document} />
      <div className="content-sections">
        <div id="join-our-team" className="content-section raleys-market alt">
          <div className="section-img-block with-text">
            {mobileImage && (
              <img
                className="section-mobile-image"
                src={mobileImage?.getOriginal()?.getUrl()}
                alt="moblie"
                data-testid="mobile-image"
              />
            )}
          </div>
          <div className="section-info-block alt">
            <div className="amira semi-bold font yellow alt" data-testid="title">
              {title}
            </div>
            <div className="text-block-10 alt" data-testid="header">
              {header}
            </div>
            <div className="columns-7 w-row">
              <div className="column-12 w-col w-col-6 w-col-stack">
                <div className="text-block-11 alt" data-testid="description">
                  {description}
                </div>
                <div className="job-opening-btn">
                  <a className="button-2 w-button" data-testid="linkUrl" href={linkUrl}>
                    <span className="text-span" data-testid="linkText">
                      {linkText}
                    </span>
                  </a>
                  <img alt="image-16" className="image-16" data-testid="linkIcon" src={linkIcon} />
                </div>
              </div>
              <div className="column-13 w-col w-col-6 w-col-stack">
                <div className="section-img-block with-text desktop job-opening">
                  <img
                    className="section-desktop-image alt"
                    src={desktopImage?.getOriginal()?.getUrl()}
                    alt="desktop"
                    data-testid="desktop-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerComponent;
