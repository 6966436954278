import React from 'react';
import { Document, Reference } from '@bloomreach/spa-sdk';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Helmet } from 'react-helmet-async';
interface TagManagerModels {
  document: Reference;
}

export function TagManagerComponent({ component, page }: BrProps) {
  const { document: documentRef } = component.getModels<TagManagerModels>();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    //if in preview mode return placeholder if no card is set on component, else return null;
    return page.isPreview() ? (
      <div className="has-edit-button" style={{ minWidth: '150px' }}>
        <BrManageContentButton content={document} />
      </div>
    ) : null;
  }

  if (page.isPreview()) {
    //if in preview mode return placeholder if no card is set on component, else return null;
    return (
      <div className="has-edit-button" style={{ minWidth: '150px' }}>
        <BrManageContentButton content={document} />
      </div>
    );
  } else {
    const { containerId } = document.getData<DocumentData>();
    let script = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${containerId}');`;
    let noScript = `<iframe title="GTM" src="https://www.googletagmanager.com/ns.html?id=${containerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    return (
      <>
        <Helmet>
          <script>{script}</script>
          <noscript>{noScript}</noscript>
        </Helmet>
      </>
    );
  }
}
