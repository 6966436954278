/*
 * Copyright 2019-2020 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { ImageSet } from '@bloomreach/spa-sdk';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';

export function NourishingContentComponent({ component, page }: BrProps) {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent(documentRef);

  // If no document selected, don't render anything
  if (!document) {
    return null;
  }

  // console.log('doc', documentRef);

  // console.log('doc', document);
  //   // If the wrong document type is selected, show a message in the CMS but not on the site
  //   const { contentType } = document.getData();
  //   if (page.isPreview() && contentType !== 'brxmbasic:heroComponent') {
  //     return <h1>Wrong document type selected. Please select a Hero Component Document</h1>
  //   }

  // Get the component document data
  const {
    card,
    cardDownload,
    content,
    flyer: flyerRef,
    header,
    title,
    titleCss,
    linkText,
    bottomLink,
    bottomIconColor,
    imageCompound,
    logo: logoRef,
    anchorID,
  } = document.getData();

  //get refs for card image
  if (card) {
    for (let i = 0; i < card.length; i++) {
      card[i].image = page.getContent<ImageSet>(card[i].imagelink);
    }
  }

  //Get the logo image
  const logo = logoRef && page.getContent<ImageSet>(logoRef);
  //console.log('LOGOOOOOOOO', logo?.getOriginal()?.getUrl());
  // if (!flyerRef) {
  //   console.log(`NourishingContentComponent: flyerRef is undefined`);
  // }
  // Get the flyer image
  const flyer = flyerRef && page.getContent<ImageSet>(flyerRef);
  // Get the mobile image
  const mobileImageRef = imageCompound?.mobileImage;
  const mobileImage = mobileImageRef && page.getContent<ImageSet>(mobileImageRef);

  // Get the desktop image
  const desktopImageRef = imageCompound?.desktopImage;
  const desktopImage = desktopImageRef && page.getContent<ImageSet>(desktopImageRef);

  return (
    <div className={`hero-block new ${page.isPreview() ? 'has-edit-button' : ''}`}>
      <BrManageContentButton content={document} />
      <div className="content-sections">
        <div id={anchorID} className="content-section raleys-market">
          <div className="section-img-block">
            {flyer && (
              <img className="flower" src={flyer?.getOriginal()?.getUrl()} alt="flower" data-testid="flyer-image" />
            )}
            {desktopImage && (
              <img
                className="section-desktop-image"
                src={desktopImage?.getOriginal()?.getUrl()}
                alt="desktop"
                data-testid="desktop-image"
              />
            )}
            {mobileImage && (
              <img
                className="section-mobile-image"
                src={mobileImage?.getOriginal()?.getUrl()}
                alt="mobile"
                data-testid="mobile-image"
              />
            )}
          </div>
          <div className="section-info-block">
            {logo && (
              <img
                className="food-for-families-logo"
                src={logo?.getOriginal()?.getUrl()}
                alt="families-logo"
                data-testid="logo-image"
              />
            )}
            <div className={`amira semi-bold font ` + titleCss} data-testid="title">
              {title}
            </div>
            <div className="text-block-10" data-testid="header">
              {header}
            </div>
            <div className="text-block-11" data-testid="content">
              {content}
            </div>
            <div className="impact-cards-section">
              {card &&
                card.map((ele, idx) => (
                  <div key={idx} className="impact-card">
                    <img
                      className="impact-card-img-1"
                      src={ele.image?.getOriginal()?.getUrl()}
                      loading="lazy"
                      alt="card-img"
                      data-testid="impact-card-img"
                    />
                    <div className="impact-card-text-block">
                      <div className="impact-card-title">
                        <div className={`impact-card-text-1 ` + ele.impactCardCss} data-testid="impact-card-text-1">
                          {ele.heading}
                        </div>
                        <div className="percentage" data-testid="percentage">
                          {ele.cardIcon}
                        </div>
                      </div>
                      <div className={`impact-card-text-2 ` + ele.impactCardCss1} data-testid="impact-card-text-2">
                        {ele.cardContent}
                      </div>
                    </div>
                  </div>
                ))}
              {cardDownload.bigfont && (
                <div className="impact-report-card-2020">
                  <div>
                    <div className="text-block-12" data-testid="text-block-12">
                      {cardDownload && cardDownload.smallfont}
                    </div>
                    <div className="text-block-13" data-testid="text-block-13">
                      {cardDownload && cardDownload.bigfont}
                    </div>
                  </div>
                  <a
                    href={cardDownload && cardDownload.string}
                    className="impact-report-2020-link w-inline-block"
                    data-testid="cardDownload-link"
                  >
                    <div className="text-block-14">Download</div>
                    <img
                      src="https://assets.website-files.com/6063cd65aa6a07d366c44ab1/606a018caf2d3e691794da6b_chevron-right.svg"
                      className="image-18"
                      alt=""
                    />
                  </a>
                </div>
              )}
            </div>
            <a href={bottomLink} className="learn-more-link w-inline-block">
              <img className="learn-more" src={bottomIconColor} alt="" />
              <div className={`learn-more-text red ` + titleCss} data-testid="linkText">
                {linkText}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
