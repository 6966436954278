import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { MenuItem as BrMenuItem } from '@bloomreach/spa-sdk';
import { MenuItem } from './MenuItem';
interface MenuItemProps extends React.ComponentProps<typeof Nav.Link> {
  item: BrMenuItem;
}

export function SidebarMenu({ item, setColor, onClick, ...props }: MenuItemProps) {
  const [showSubnav, setSubnav] = useState(false);
  const handleClick = () => setSubnav(!showSubnav);

  const activeClass = item.isSelected() || showSubnav ? 'active' : '';
  const hasChildren = item.getChildren().length > 0;

  const className = item.getParameters()['Menu CSS'];
  const parentCssClass = item.getParameters()['Sidebar CSS'];
  const ChevronDown = require('../../assets/icons/chevron-down.svg');

  // console.log('parentCssClass', parentCssClass);
  // const chooseColor = () => {
  //   if (parentCssClass === 'red' || 'pink' || 'green') {
  //     setIcon('light');
  //   }
  //   // else {
  //   //   setIcon('dark');
  //   // }
  //   if (parentCssClass === 'yellow' || '') {
  //     setIcon('dark');
  //   }
  // };

  useEffect(() => {
    if (item.isSelected()) {
      setColor(parentCssClass);
      // chooseColor();
    }
  });
  // console.log('parentCssClass', parentCssClass);
  return hasChildren ? (
    <div className={`nav-sidebar-section ${activeClass}`}>
      <div className="nav-sidebar-title-block">
        <div
          className={`nav-sidebar-list-title ${className} ${item.isSelected() ? activeClass : ''}`}
          key={item.getName()}
        >
          <MenuItem bsPrefix="nav-sidebar-link" item={item} onClick={onClick} />
        </div>
        <img
          src={ChevronDown}
          loading="lazy"
          className={`nav-sidebar-arrow ${activeClass}`}
          role="button"
          onClick={handleClick}
          alt=""
        />
      </div>
      <ul className={`nav-sidebar-list w-list-unstyled ${activeClass}`}>
        {item.getChildren().map((subItem) => {
          var subItemAnchorId = subItem.getParameters()['AnchorId'];
          return (
            <li className="nav-sidebar-list-item" key={subItem.getName()}>
              <MenuItem
                bsPrefix="nav-sidebar-link"
                item={subItem}
                href={subItemAnchorId && `${item.getUrl()}${subItemAnchorId}`}
              />
            </li>
          );
        })}
      </ul>
    </div>
  ) : (
    <div className={`nav-sidebar-list-title single ${className} ${activeClass}`} key={item.getName()}>
      <MenuItem bsPrefix="nav-sidebar-link" item={item} onClick={onClick} />
    </div>
  );
}
