import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document, Reference } from '@bloomreach/spa-sdk';
import React from 'react';

interface DonationCardComponentModels {
  document: Reference;
}

export function DonationCardComponent({ component, page }: BrProps) {
  const { document: documentRef } = component.getModels<DonationCardComponentModels>();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    //if in preview mode return placeholder if no card is set on component, else return null;
    return page.isPreview() ? (
      <div className="has-edit-button" style={{ minWidth: '150px' }}>
        <BrManageContentButton content={document} />
      </div>
    ) : null;
  }
  const { cardCss, cardTitle, cardTitleCss, cardText, cardLink } = document.getData<DocumentData>();
  console.log('cardcss', cardCss);
  return (
    <div className="content-sections">
      <div className={`${cardCss}`}>
        <div className="donate-logo-container">
          <img
            src="https://assets.website-files.com/6063cd65aa6a07d366c44ab1/60676e5209bea43fb20a2a85_food-for-families-logo.png"
            loading="lazy"
            alt=""
            className="image-19"
          />
        </div>
        <div className="div-block-3" data-testid="cardContent">
          {cardTitle && <div className={`${cardTitleCss}`}>{cardTitle}</div>}
          {cardText && <div dangerouslySetInnerHTML={{ __html: page.rewriteLinks(cardText.value) }} />}
          {cardLink && (
            <a href={cardLink.link || cardLink.linkAnchor} className={`${cardLink.linkCssClass} w-button`}>
              Donate Now
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
