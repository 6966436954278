/*
 * Copyright 2019-2020 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Environment Variables
import env from '@beam-australia/react-env';
import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
//error handling
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import './App.css';
import {
  Banner,
  CardComponent,
  CareerComponent,
  Content,
  DonationCardComponent,
  Footer,
  HeaderComponent,
  HeroComponent,
  ImageTextHorizontalCardComponent,
  NewsFeedComponent,
  NewsList,
  NourishingContentComponent,
  SearchEngineOptimization,
  Sidebar,
  TagManagerComponent,
  TextCardComponent,
} from './components';
import ErrorFallback from './components/ErrorFallBack';
// CSS
import './raleys-purpose-page.css';

export default function App(props: RouteComponentProps) {
  const configuration = {
    baseUrl: env('BASE_URL'),
    endpoint: env('BRXM_ENDPOINT'),
    endpointQueryParameter: 'brxm',
    httpClient: axios,
    origin: env('CMS_ORIGIN'),
    request: {
      path: `${props.location.pathname}${props.location.search}`,
    },
  };

  /* Console log displaying the current configuration. */
  console.log(configuration);

  const mapping = {
    Banner,
    Content,
    HeaderComponent,
    HeroComponent,
    ImageTextHorizontalCardComponent,
    CardComponent,
    TextCardComponent,
    NourishingContentComponent,
    NewsFeedComponent,
    NewsList,
    DonationCardComponent,
    'Simple Content': Content,
    TagManagerComponent,
    SEO: SearchEngineOptimization,
    CareerComponent,
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen);
    setSidebarColor('');
  };
  const [sidebarColor, setSidebarColor] = useState('');
  const [hamburgerIcon, setHamburgerIcon] = useState('');
  const setColor = (color) => {
    setSidebarColor(color);
  };

  // const setIcon = (iconColor) => {
  //   setHamburgerIcon(iconColor);
  // };

  //console.log('hamburgerIcon', hamburgerIcon);
  const HamburgerDarkIcon = require('./assets/icons/hamburger.svg');
  const HamburgerLightIcon = require('./assets/icons/hamburger_light.svg');
  const CloseIcon = require('./assets/icons/close.svg');

  const { pathname } = useLocation();

  useEffect(() => {
    const onTop = async () => {
      window.scrollTo(0, 0);
    };
    onTop();
  }, [pathname]);

  //console.log('sidebarColor', 'nav ' + sidebarColor);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <BrPage configuration={configuration} mapping={mapping}>
          <div className="flexbox nav-c">
            <div className="fake-nav-space" />
            <div className={`nav ${sidebarColor}`}>
              <img
                src={
                  `nav ${sidebarColor}` === 'nav ' || `nav ${sidebarColor}` === ' nav yellow '
                    ? HamburgerDarkIcon
                    : HamburgerLightIcon
                }
                loading="lazy"
                alt=""
                className="nav-ham"
                onClick={handleToggle}
              />
            </div>
            <div className="content">
              <div className="header-block" style={{ width: '100%', display: 'block' }}>
                <BrComponent path="header" />
              </div>
              <span style={{ width: '100%' }}>
                <BrComponent path="hero" />

                {!sidebarOpen && (
                  <img
                    src={HamburgerLightIcon}
                    loading="lazy"
                    alt=""
                    className="nav-ham-mobile"
                    onClick={handleToggle}
                  />
                )}
              </span>

              <div className="content-block" style={{ width: '100%' }}>
                <BrComponent path="main" />
              </div>
            </div>
            <div className={`nav-sidebar ${sidebarOpen ? 'active' : ''}`}>
              <img src={CloseIcon} loading="lazy" alt="" className="nav-sidebar-close-btn" onClick={handleToggle} />
              <BrComponent path="menu">
                <Sidebar setColor={setColor} onClick={handleToggle} />
              </BrComponent>
            </div>
          </div>
          <Footer />
        </BrPage>
      </HelmetProvider>
    </ErrorBoundary>
  );
}
