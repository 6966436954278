import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, Menu as BrMenu, TYPE_LINK_EXTERNAL, isMenu } from '@bloomreach/spa-sdk';
import { BrComponentContext, BrManageMenuButton, BrPageContext } from '@bloomreach/react-sdk';

import FooterStoreLocator from './FooterStoreLocator';
import FooterEmail from './FooterEmail';
import FooterDownloadMenu from './FooterDownloadMenu';
interface MenuLinkProps {
  item: MenuItem;
}

export function MenuLink({ item }: MenuLinkProps) {
  const url = item.getUrl();

  // No Link
  if (!url) {
    return <span className="link">{item.getName()}</span>;
  }
  // External Link
  if (item.getLink()?.type === TYPE_LINK_EXTERNAL) {
    return (
      <a href={url} className="link">
        {item.getName()}
      </a>
    );
  }

  // Internal Link
  return (
    <Link to={url} className="link">
      {item.getName()}
    </Link>
  );
}

export function FooterCollapsible({ item }: MenuLinkProps) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="footer-list">
      <div className="footer-col-title-block">
        <div className="footer-title">{item.getName()}</div>
        <img
          onClick={() => setCollapsed(!collapsed)}
          src="https://assets.website-files.com/6063cd65aa6a07d366c44ab1/6068b718fb08b467c370313d_caret-up.svg"
          loading="lazy"
          width="10"
          alt=""
          className={`footer-list-arrow ${collapsed ? 'inactive' : ''}`}
        />
      </div>

      <ul className={`footer-list-items w-list-unstyled ${collapsed ? 'inactive' : ''}`}>
        {item.getChildren().map((thirdLevelItem: MenuItem, index: number) => (
          <li className="footer-link" key={index}>
            <MenuLink item={thirdLevelItem} />
          </li>
        ))}
      </ul>
    </div>
  );
}

const FooterMain = () => {
  const component = React.useContext(BrComponentContext);
  const page = React.useContext(BrPageContext);
  const menuRef = component?.getModels<MenuModels>()?.menu;
  const menu = menuRef && page?.getContent<BrMenu>(menuRef);

  if (!isMenu(menu)) {
    return null;
  }

  return (
    <div className={`link-columns w-row ${page!.isPreview() ? 'has-edit-button' : ''}`} data-testid="MainMenu">
      <BrManageMenuButton menu={menu} />

      {menu.getItems().map((item: MenuItem, index: number) => (
        <div className="footer-col w-col w-col-2 w-col-stack" key={index}>
          {item.getChildren().map((secondLevelItem: MenuItem, index: number) => (
            <FooterCollapsible item={secondLevelItem} key={index} data-testid="collapsible" />
          ))}
        </div>
      ))}

      <div className="footer-col w-col w-col-2 w-col-stack">
        <FooterStoreLocator />
        <FooterEmail />
        <FooterDownloadMenu />
      </div>
    </div>
  );
};

export default FooterMain;
